import React, {useCallback, useContext, useEffect, useRef, useState,} from 'react';
import {
  getAlbums,
  getCurrentGame,
  getDailyProgress,
  getFirstRound,
  getGameConfig, setAllDone,
  setAnswer,
  setHint,
  startGame,
} from '../../../api/apiGame';
import {
  collectDailyBonus,
  collectPersonalPick,
  deleteUserPersonalCard,
  getUserPersonalCards,
} from '../../../api/userPersonalCard';
import DogCard from '../../dog-card/DogCard';
import Score from '../score/Score';
import {useNavigate} from 'react-router-dom';
import {getAllBreeds} from '../../../api/apiService';
import {getDogDestructivePhrases} from '../../../api/dogDestructivePhrases';
import AppContext from '../../../common/context/app-context';
import {GUEST_TOKEN_KEY, guestLoginApi} from '../../../api/apiAuth';
import {Popup} from '../../../common/components/AuthModal';
import useQueryParams from '../../../common/hooks/useQueryParams';
import {
  useTrackGoogleAnalyticsEvent,
  useTrackGoogleAnalyticsEventPages
} from '../../../common/hooks/useTrackGoogleAnalyticsEvent';
import {getAuth} from 'firebase/auth';
import {mixpanelTrack} from '../../../utils/mixpanel-track';
import {getCourseItemData} from "../../../api/apiCourse";

export enum RoundType {
  TileGame = 1,
  FourPhoto,
  SinglePhoto,
  SingleVideo,
  FindTheLie,
}

type RoundEventData = { roundNumber: number; type: string };

interface GameProp {
  isCourse?: boolean;
  courseId?: string,
  url?: string;
  imageLoadEndHandler?: (img: string) => void;
  previewClass?: string;
}

const postMessageToParent = (data: { type: string; data: any }): void => {
  window.parent?.postMessage(data, "*");
};

const Game: React.FC<GameProp> = ({
  isCourse = false,
  courseId,
  url = "",
  imageLoadEndHandler,
  previewClass = "game-iframe",
}) => {
  const navigate = useNavigate();
  const { showMode } = useQueryParams();
  const logGoogleEvent = useTrackGoogleAnalyticsEvent();

  const gameFrameRef = useRef<HTMLIFrameElement | null>(null);
  const gameConfig: any = useRef();
  const {setShowAuthPopup, setIsGameMode, me, setAlbums} = useContext(AppContext);
  let lastRound: RoundEventData | null = null;

  const [hasEvent, setHasEvent] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openScore, setOpenScore] = useState<boolean>(false);
  const [finishData, setFinishData] = useState<any>(null);
  const [userPersonalCardId, setUserPersonalCardId] = useState<
    number | undefined
  >();
  const [imageData, setImageData] = useState<string>("");

  const postMessage = useCallback(
    (data: { type: string; data: any }): void => {
      if (gameFrameRef?.current) {
        gameFrameRef.current.contentWindow?.postMessage(data, "*");
      }
    },
    [gameFrameRef?.current],
  );
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/game', 'game page')

  }, []);
  useEffect(() => {
    setOpenScore(() => false);
    setImageData(() => "")
    postMessage({
      type: 'newCourse',
      data: {courseId},
    });
  }, [courseId]);


  useEffect(() => {
    const initGuest = async () => {
      if (!localStorage.getItem(GUEST_TOKEN_KEY)) {
        const guestToken = await guestLoginApi();
        localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);
      }
    };
    if (url !== "?openScrapBook" && url !== "?firstRoundImage") {
      getAuth()
        .authStateReady()
        .then(() => initGuest());
    }
  }, [url]);

  useEffect(() => {
    if (!me || !hasEvent) return;
    setHasEvent(false);
    getAlbums().then((albums) => {
      postMessage({
        type: 'getAlbums',
        data: {...albums, userId: me.id},
      });
    });
  }, [me, hasEvent]);

  useEffect(() => {
    const handleMessage = async ({ data }: any) => {
      const user = me;
      if (data.type == null) {
        return;
      }
      const currentData = data?.data ?? {};

      switch (data.type) {
        case 'setItemToLocalStorage':{
          localStorage.setItem(currentData.key, currentData.value);
          break;
        }
        case 'setEventsToMixpanel': {
          mixpanelTrack(currentData.eventName, {data: currentData.data});
          break;
        }
        case 'getItemFromLocalStorage':{
          postMessage({
            type: "getItemFromLocalStorage",
            data: localStorage.getItem(currentData.key),
          });
          break;
        }
        case "gameSetup": {
          postMessage({
            type: "gameSetup",
            data: currentData,
          });
          if(showMode) {
            gameConfig.current = currentData
          }
          break;
        }
        case "loadEnd": {
          if (showMode) {
            postMessageToParent({
              type: "loadEnd",
              data: {},
            });
            return;
          }
          let type = "loadEnd";
          if (isCourse) {
            try {
              gameConfig.current = await getCourseItemData(courseId || '',
                user
                  ? {}
                  : {
                    baseURL: process.env.REACT_APP_SERVER_API?.replace(
                      "client",
                      "guest",
                    )
                  }
              );
            } catch (e: any) {
              if (e?.response?.data?.errorCode === 'NO_UID') {
                const guestToken = await guestLoginApi();
                localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);
                try {
                  gameConfig.current = await getCourseItemData(courseId || '',
                    user
                      ? {}
                      : {
                        baseURL: process.env.REACT_APP_SERVER_API?.replace(
                          "client",
                          "guest",
                        )
                      }
                  );
                }catch (e: any) {
                  console.error(e);
                }
                type = "ongoingConfig";
              }
            }
            type = "ongoingConfig";
          } else {
            try {
              gameConfig.current = await getCurrentGame(
                user
                  ? {}
                  : {
                    baseURL: process.env.REACT_APP_SERVER_API?.replace(
                      "client",
                      "guest",
                    ),
                  },
              );
              type = "ongoingConfig";
            } catch (e: any) {
              if (e?.response?.data?.errorCode === 'NO_UID') {
                const guestToken = await guestLoginApi();
                localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);
                try {
                  gameConfig.current = await getCurrentGame(
                    user
                      ? {}
                      : {
                        baseURL: process.env.REACT_APP_SERVER_API?.replace(
                          'client',
                          'guest',
                        ),
                      },
                  );
                  type = 'ongoingConfig';
                } catch (e) {
                  try {
                    gameConfig.current = await getGameConfig(
                      user
                        ? {}
                        : {
                          baseURL: process.env.REACT_APP_SERVER_API?.replace(
                            'client',
                            'guest',
                          ),
                        },
                    );
                    type = 'gameConfig';
                  } catch (e) {
                    postMessage({
                      type: 'gameConfigError',
                      data: (e as any)?.response?.data,
                    });
                  }
                }
              } else {
                try {
                  gameConfig.current = await getGameConfig(
                    user
                      ? {}
                      : {
                        baseURL: process.env.REACT_APP_SERVER_API?.replace(
                          'client',
                          'guest',
                        ),
                      },
                  );
                  type = 'gameConfig';
                } catch (e) {
                  postMessage({
                    type: 'gameConfigError',
                    data: (e as any)?.response?.data,
                  });
                }
              }
            }
          }
          postMessage({
            type,
            data: {...gameConfig, isGuest: !user, userId: user?.id},
          });
          break;
        }
        case 'openTopics': {
          navigate('/topics');
          break;
        }
        case 'submitDog': {
          navigate('/submit-dog-step-1');
          break;
        }
        case 'getFirstRound': {
          const firstRound = await getFirstRound();
          if (showMode) {
            postMessageToParent({
              type: 'getFirstRound',
              data: {},
            });
            return;
          }
          postMessage({
            type: 'getFirstRound',
            data: firstRound,
          });
          break;
        }
        case "daily-progress" : {
          const dailyProgress = await getDailyProgress();
          postMessage({
            type: "daily-progress",
            data: dailyProgress,
          });
          break;
        }
        case "all-done" : {
          const response = await setAllDone();
          postMessage({
            type: "all-done",
            data: response,
          });
          break;
        }
        case "firstRoundForShow": {
          postMessage({
            type: "getFirstRound",
            data: currentData,
          });
          break;
        }
        case "imageReady": {
          setImageData(currentData);
          if (imageLoadEndHandler) {
            imageLoadEndHandler(currentData);
          }
          break;
        }
        case "collectUserPupStar": {
          if (me) {
            const albums = await getAlbums();
            setAlbums(albums);
          }
          break;
        }
        case "collectDailyBonus": {
          await collectDailyBonus(currentData.id);
          if (me) {
            const albums = await getAlbums();
            setAlbums(albums);
          }
          postMessage({
            type: "DailyBonusCollected",
            data: {},
          });
          break;
        }
        case "Login":
          if (!me) {
            setShowAuthPopup(() => ({
              authStep: Popup.Login,
              options: { postLoggedInEventToGame: true },
            }));
          }
          break;
        case "loggedInEventToGame":
          postMessage({ type: "login", data: true });
          break;
        case "startGame":
          const gameInit = await startGame(
            gameConfig.current.id,
            user
              ? {}
              : {
                  baseURL: process.env.REACT_APP_SERVER_API?.replace(
                    "client",
                    "guest",
                  ),
                },
          );
          lastRound = {
            roundNumber: gameInit?.rounds?.roundsCount,
            type: RoundType[gameInit.rounds?.roundTypeId],
          };
          postMessage({
            type: "startData",
            data: gameInit,
          });
          break;
        case "getUserPersonalPickCards":
          const userPersonalCards = await getUserPersonalCards();
          postMessage({
            type: "getUserPersonalPickCards",
            data: userPersonalCards,
          });
          break;
        case "editUserPersonalPickCard": {
          const id = currentData.id;
          setUserPersonalCardId(id);
          setOpenModal(true);
          postMessage({
            type: "editUserPersonalPickCard",
            data: { id },
          });
          break;
        }
        case "createUserPersonalPickCard":
          setOpenModal(true);
          setUserPersonalCardId(undefined);
          postMessage({
            type: "createUserPersonalPickCard",
            data: {},
          });
          break;
        case "collectUserPersonalPickCard":
          const id = currentData.id;
          const res = await collectPersonalPick(id);
          postMessage({
            type: "collectUserPersonalPickCard",
            data: { res },
          });
          break;
        case "deleteUserPersonalPickCard": {
          const id = currentData.id;
          const res = await deleteUserPersonalCard(id);
          postMessage({
            type: "deleteUserPersonalPickCard",
            data: res,
          });
          break;
        }
        case "closeUserPersonalPickCard":
          setOpenModal(false);
          postMessage({
            type: "closeUserPersonalPickCard",
            data: {},
          });
          break;
        case "answer":
          const answerData = await setAnswer(
            gameConfig.current.id,
            currentData.answerId,
            currentData.secondAnswer,
            user
              ? {}
              : {
                  baseURL: process.env.REACT_APP_SERVER_API?.replace(
                    "client",
                    "guest",
                  ),
                },
          );
          postMessage({
            type: "answer",
            data: answerData,
          });
          break;
        case "hint":
          const hintData = await setHint(
            gameConfig.current.id,
            user
              ? {}
              : {
                  baseURL: process.env.REACT_APP_SERVER_API?.replace(
                    "client",
                    "guest",
                  ),
                },
          );
          postMessage({
            type: "hint",
            data: hintData,
          });
          break;
        case "getAlbums":
          setHasEvent(true);
          break;
        case "gameFinish":
          !showMode &&
            logGoogleEvent("Game", `Completed-gameId-${currentData.gameId}`);
          setFinishData(() => ({ ...currentData, showMode }));
          setOpenScore(() => true);
          postMessage({
            type: "imageReady",
            data: {},
          });
          break;
        case "exit":
          navigate("/");
          break;
        case "howToPlay":
          navigate("/how-to-play");
          break;
        case "reload":
          postMessage({ type: "reload", data: {}})
          break;
        case "getAllBreeds":
          const breeds = await getAllBreeds();
          postMessage({
            type: "getAllBreeds",
            data: breeds,
          });
          break;
        case "getDogDestructivePhrases":
          const phrases = await getDogDestructivePhrases();
          postMessage({
            type: "getDogDestructivePhrases",
            data: phrases,
          });
          break;
        case 'personalPickCardPage':
          window.scrollTo(0, 0);
          break;
        default: {
          console.error("Unknown message key: ", data.type);
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
      if (url !== "?openScrapBook" && url !== "?firstRoundImage" && !showMode) {
        if (!finishData) {
          logGoogleEvent(
            "Game",
            `Abandoned-Round Number-${lastRound?.roundNumber}-Round Type-${lastRound?.type}`,
          );
        } else {
          logGoogleEvent("Game", `Completed`);
        }
      }
    };
  }, [gameFrameRef.current, me, courseId]);


  useEffect(() => {
    if (url === "?firstRoundImage") return;
    setIsGameMode(true);
    let root = document.getElementById("root");
    let gameHolderShow = document.getElementById("game-iframe-holder");
    root?.classList.add("game");
    if (showMode && gameHolderShow) {
      gameHolderShow.classList.remove("game-holder");
      gameHolderShow.classList.add("game-holder-show");
    }

    return () => {
      setIsGameMode(false);
      root?.classList.remove("game");
      root = null;
      gameHolderShow = null;
    };
  }, [url, setIsGameMode, showMode]);

  return (
        <>
          {openModal && (
            <DogCard
              id={userPersonalCardId}
              onClose={(data) => {
                setOpenModal(!openModal);
                postMessage({type: 'closeUserPersonalPickCard', data}); // TODO
              }}
            ></DogCard>
          )}
          <div
            id="game-iframe-holder"
            className={'game-holder' + (openScore ? ' invisible' : '')}
          >
            {!imageData && me !== undefined && (

              <iframe
                className={previewClass}
                src={`${process.env.REACT_APP_GAME_URL}${url}`}
                title="game"
                ref={gameFrameRef as any}
              ></iframe>
            )}
          </div>
          {openScore && (
            <Score data={finishData} roundCount={gameConfig.current.rounds.length} userId={me?.id} imageData={imageData}/>
          )}
        </>
  );
};

export default Game;
